import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CourseTemplateService } from '../../../services/course-template.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { TrainingTypeModel } from '../../../models/training/training-type.model';
import { CourseTemplateFullModel } from 'src/app/models/course/course-template-full.model';
import {
  categoryIcon,
  clockIcon,
  playIcon,
  bookmarkIcon,
  bookmarkOutlinedIcon,
} from '../../../app-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import {
  combineLatest,
  forkJoin,
  Observable,
  of,
  repeat,
  Subscription,
  throwError,
  timer,
} from 'rxjs';
import { CourseService } from '../../../services/course.service';
import { ToastrService } from 'ngx-toastr';
import { CourseContentTypeModel } from '../../../models/course/course-content-type.model';
import { SCORMAdapter } from '../../../scorm/scorm-adapter';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { CourseParticipationStatusModel } from '../../../models/course/course-participation-status.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { PersonEventFlagService } from 'src/app/services/person-event-flag.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-course-info',
  templateUrl: './course-info.component.html',
  styleUrls: ['./course-info.component.scss'],
})
export class CourseInfoComponent implements OnDestroy, AfterViewInit {
  private _videoPlayer!: ElementRef;

  @ViewChild('videoPlayer')
  set videoPlayer(value: ElementRef) {
    this._videoPlayer = value;

    if (
      this.courseTemplate?.courseTemplate.courseContentType ==
      CourseContentTypeModel.AzureVideo
    ) {
      const videoElement = this._videoPlayer.nativeElement as HTMLVideoElement;
      videoElement.addEventListener('loadedmetadata', () => {
        const durationInSeconds = videoElement.duration;
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);

        this.videoDuration = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
      });
    }
  }

  get videoPlayer(): ElementRef {
    return this._videoPlayer;
  }

  // @ViewChild('videoPlayer') videoPlayer!: ElementRef;
  isVideoPlaying = false;

  courseTemplateId: string;
  videoDuration: string | null = null;
  TrainingType = TrainingTypeModel;
  courseTemplate: CourseTemplateFullModel | null = null;
  participationStatus: CourseParticipationStatusModel =
    CourseParticipationStatusModel.NoParticipation;
  videoProgressSeconds: number = 0;
  showModal = false;
  isAdmin = false;
  isTrainingCatalogue = false;
  isBookmarked = false;

  private subs = new Subscription();

  get trainingType() {
    switch (this.courseTemplate?.courseTemplate.trainingType) {
      case TrainingTypeModel.General:
        return 'General';
      case TrainingTypeModel.Compliance:
        return 'Compliance';
      case TrainingTypeModel.IDD:
        return 'IDD';
      default:
        return '';
    }
  }

  get trainingGroups() {
    if (!this.courseTemplate?.trainingGroups.length) {
      return '';
    } else {
      return this.courseTemplate?.trainingGroups
        .map((group) => group.name)
        .join(', ');
    }
  }

  get durationString() {
    if (
      !this.courseTemplate ||
      !this.courseTemplate?.courseTemplate ||
      typeof this.courseTemplate?.courseTemplate.iddTimeMinutes !== 'number' ||
      isNaN(this.courseTemplate?.courseTemplate.iddTimeMinutes)
    ) {
      return '00:00';
    }

    const hours = Math.floor(
      this.courseTemplate?.courseTemplate.iddTimeMinutes / 60
    );
    const remainingMinutes =
      this.courseTemplate?.courseTemplate.iddTimeMinutes % 60;
    const durationString = `${hours}:${
      remainingMinutes < 10 ? '0' : ''
    }${remainingMinutes}`;

    return durationString;
  }

  get trainingCategories() {
    if (
      this.courseTemplate &&
      this.courseTemplate.trainingCategories &&
      this.courseTemplate.trainingCategories.length
    ) {
      return this.courseTemplate.trainingCategories
        .map((category) => category.name)
        .join(', ');
    } else {
      return null;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private courseTemplateService: CourseTemplateService,
    private personEventFlagService: PersonEventFlagService,
    private loadingService: LoadingService,
    private router: Router,
    private location: Location,
    private sanitized: DomSanitizer,
    private courseService: CourseService,
    private authorizationService: AuthorizationService,
    private toastrService: ToastrService,
    private httpClient: HttpClient,
    private translateService: TranslateService
  ) {
    this.isTrainingCatalogue = !!this.route.snapshot.params['categoryId'];
    this.courseTemplateId = this.route.snapshot.params['id'];

    const obs = forkJoin([
      this.courseTemplateService.getCourseTemplate(this.courseTemplateId),
      this.courseService
        .getParticipationStatus(this.courseTemplateId)
        .pipe(
          catchError(() => of(CourseParticipationStatusModel.NoParticipation))
        ),
      this.personEventFlagService.getPersonEventFlags(this.courseTemplateId),
    ]);
    this.loadingService
      .load(obs)
      .subscribe(([courseTemplate, participationStatus, personEventFlags]) => {
        this.courseTemplate = courseTemplate;
        this.participationStatus = participationStatus;
        this.isBookmarked = !!personEventFlags.find(
          (flag) => flag.actionFlag == 1
        );

        if (
          this.courseTemplate &&
          this.courseTemplate.courseTemplate &&
          this.courseTemplate.courseTemplate.courseContentType !==
            CourseContentTypeModel.External
        ) {
          this.courseService
            .trackVideoProgress(this.courseTemplateId, 0)
            .subscribe(
              (webinarAttendee) =>
                (this.videoProgressSeconds =
                  webinarAttendee.videoProgressSeconds)
            );
        }
      });

    const sub = timer(2500, 2500)
      .pipe(
        filter(
          () =>
            this.courseTemplate != null &&
            this.courseTemplate.courseTemplate.courseContentType ==
              CourseContentTypeModel.AzureVideo
        )
      )
      .subscribe(() => this.updatePlayTime());
    this.subs.add(sub);

    this.authorizationService
      .getCurrentPerson()
      .pipe(first())
      .subscribe((currentUser) => {
        if (currentUser.person.role.name == 'SuperAdmin') {
          this.isAdmin = true;
        }
      });
  }

  toggleBookmark() {
    if (this.isBookmarked) {
      this.loadingService
        .load(
          this.personEventFlagService.deletePersonEventFlag(
            this.courseTemplateId,
            'Bookmark'
          )
        )
        .subscribe(() => {
          this.toastrService.success(
            this.translateService.instant(
              'TRAINING-CATALOGUE.UNBOOKMARK-SUCCESS'
            )
          );
          this.isBookmarked = false;
        });
    } else {
      this.loadingService
        .load(
          this.personEventFlagService.addPersonEventFlag(
            this.courseTemplateId,
            'Bookmark'
          )
        )
        .subscribe(() => {
          this.toastrService.success(
            this.translateService.instant('TRAINING-CATALOGUE.BOOKMARK-SUCCESS')
          );
          this.isBookmarked = true;
        });
    }
  }

  getPlayIcon() {
    return this.sanitized.bypassSecurityTrustHtml(playIcon);
  }

  getClockIcon() {
    return this.sanitized.bypassSecurityTrustHtml(clockIcon);
  }

  getCategoryIcon() {
    return this.sanitized.bypassSecurityTrustHtml(categoryIcon);
  }

  getBookmarkIcon() {
    return this.sanitized.bypassSecurityTrustHtml(bookmarkIcon);
  }

  getBookmarkOutlinedIcon() {
    return this.sanitized.bypassSecurityTrustHtml(bookmarkOutlinedIcon);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private updatePlayTime() {
    if (!this.videoPlayer?.nativeElement) {
      return;
    }
    const videoElement = this.videoPlayer.nativeElement as HTMLVideoElement;
    if (videoElement.currentTime > this.videoProgressSeconds) {
      this.videoProgressSeconds = videoElement.currentTime;
      this.courseService
        .trackVideoProgress(this.courseTemplateId, this.videoProgressSeconds)
        .subscribe();
    }
  }

  onStartCourseClick(): void {
    if (
      this.courseTemplate?.courseTemplate.courseContentType ==
      CourseContentTypeModel.AzureVideo
    ) {
      this.playVideo();
    } else if (
      this.courseTemplate?.courseTemplate.courseContentType ==
      CourseContentTypeModel.AzureScorm
    ) {
      this.startScorm();
    } else if (
      this.courseTemplate?.courseTemplate.courseContentType ==
      CourseContentTypeModel.External
    ) {
      this.startExternal();
    }
  }

  startScorm(): void {
    const scormAdapter = new SCORMAdapter(
      this.httpClient,
      this.courseTemplateId
    );
    scormAdapter.uri = '/scorm/lms';
    // @ts-ignore
    window.API = scormAdapter;

    // const entryPointUrl =
    //    +
    //   this.courseTemplateId +
    //   '/scormdriver/indexAPI.html';
    // console.log('entrypoint', entryPointUrl);
    // @ts-ignore
    this.authorizationService
      .getCurrentPerson()
      .pipe(
        switchMap((person) =>
          scormAdapter.loadValuesFromLmsAndInitData(
            person.person.id,
            person.person.firstName + ' ' + person.person.lastName
          )
        ),
        switchMap(() =>
          this.loadingService.load(
            this.courseTemplateService.getScormEntrypoint(this.courseTemplateId)
          )
        ),
        // map(entrypointFile => 'https://localhost:7075' + '/api/scormcontent/' + this.courseTemplateId + '/' + entrypointFile),
        map(
          (entrypointFile) =>
            window.location.origin +
            '/api/scormcontent/' +
            this.courseTemplateId +
            '/' +
            entrypointFile
        ),
        tap((entrypoint) => console.log('entrypoint', entrypoint)),
        switchMap((entrypoint) => {
          const myWindow = window.open(
            entrypoint,
            'MyWindow',
            'width=600,height=300'
          );
          if (myWindow == null) {
            this.toastrService.error(
              'Could not start scorm course. Please allow popup windows in your browser settings'
            );
            return of(false);
          }
          return of(true);
        }),
        filter((windowOpened) => windowOpened),
        switchMap(() => scormAdapter.isFinished$),
        switchMap(() =>
          this.loadingService.load(
            this.courseService.getParticipationStatus(this.courseTemplateId)
          )
        ),
        filter(
          (participationStatus) =>
            participationStatus == CourseParticipationStatusModel.Completed
        ),
        switchMap(() => this.completeOrRedirectToQuestions())
      )
      .subscribe((participationStatus) =>
        console.log('closed window', participationStatus)
      );
  }

  playVideo() {
    const videoElement = this.videoPlayer.nativeElement as HTMLVideoElement;
    videoElement.onseeking = () => {
      if (videoElement.currentTime > this.videoProgressSeconds) {
        videoElement.currentTime = this.videoProgressSeconds;
      }
    };

    videoElement.onended = () => {
      document.exitFullscreen();
      this.completeOrRedirectToQuestions().subscribe();
    };

    videoElement.play();
    videoElement.requestFullscreen();
    this.isVideoPlaying = true;
  }

  private completeOrRedirectToQuestions(): Observable<void> {
    if (!this.courseTemplate) {
      this.toastrService.error('Error');
      return throwError(() => {});
    } else if (this.courseTemplate.questions.length > 0) {
      this.showModal = true;
      return of();
    } else {
      return this.loadingService
        .load(this.courseService.complete(this.courseTemplateId))
        .pipe(
          tap(() => {
            this.navigateQuestions(true);
          }),
          switchMap(() => of())
        );
    }
  }

  navigateQuestions(isSkip: boolean = false): void {
    if (isSkip) {
      this.router.navigate(
        ['my-trainings', 'course', this.courseTemplateId, 'questions'],
        { queryParams: { skipQuestions: true } }
      );
    } else {
      this.router.navigate([
        'my-trainings',
        'course',
        this.courseTemplateId,
        'questions',
      ]);
    }
  }

  navigateBack(): void {
    if (this.isTrainingCatalogue) {
      this.router.navigate([
        `/training-catalogue/${this.route.snapshot.params['categoryId']}`,
      ]);
    } else {
      this.router.navigate(['/my-trainings']);
    }
  }

  protected readonly CourseContentTypeModel = CourseContentTypeModel;

  ngAfterViewInit(): void {}

  private startExternal() {
    const windowObs = this.loadingService
      .load(this.courseService.getExternalUrl(this.courseTemplateId))
      .pipe(
        switchMap((externalUrl) => {
          const myWindow = window.open(
            externalUrl,
            'MyWindow',
            'width=' + screen.availWidth + ',height=' + screen.availHeight
          );
          if (myWindow == null) {
            this.toastrService.error(
              'Could not start course. Please allow popup windows in your browser settings'
            );
          }
          return of(myWindow);
        }),
        filter((window) => window != null)
      );
    combineLatest([windowObs, timer(1000, 250)])
      .pipe(
        repeat(),
        filter(([w]) => w!.closed),
        take(1),
        switchMap(() =>
          this.loadingService.load(
            this.courseService.getParticipationStatus(this.courseTemplateId)
          )
        ),
        filter(
          (participationStatus) =>
            participationStatus == CourseParticipationStatusModel.Completed
        ),
        switchMap(() =>
          this.loadingService.load(
            this.courseService.complete(this.courseTemplateId)
          )
        )
      )
      .subscribe(() => this.completeOrRedirectToQuestions());
  }

  protected readonly CourseParticipationStatusModel =
    CourseParticipationStatusModel;
}
