<div class="course-info" *ngIf="courseTemplate">
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <dx-button
        *ngIf="!isAdmin"
        [text]="
          (participationStatus == 1
            ? 'MY-TRAININGS.OVERVIEW.CONTINUE-COURSE'
            : participationStatus == 2
            ? 'MY-TRAININGS.OVERVIEW.REPEAT-COURSE'
            : 'MY-TRAININGS.OVERVIEW.START-COURSE'
          ) | translate
        "
        type="normal"
        class="integration-btn"
        (click)="onStartCourseClick()"
      >
      </dx-button>
      <dx-drop-down-button
        *ngIf="isAdmin"
        [splitButton]="true"
        [text]="
          (participationStatus == 1
            ? 'MY-TRAININGS.OVERVIEW.CONTINUE-COURSE'
            : participationStatus == 2
            ? 'MY-TRAININGS.OVERVIEW.REPEAT-COURSE'
            : 'MY-TRAININGS.OVERVIEW.START-COURSE'
          ) | translate
        "
        stylingMode="contained"
        type="normal"
        [dropDownOptions]="{ width: 230 }"
        (onButtonClick)="onStartCourseClick()"
      >
        <dxi-item (click)="onAdminViewClick()">
          {{ "COMMON.ADMIN-VIEW" | translate }}
        </dxi-item>
      </dx-drop-down-button>
    </div>
    <div class="course-heading">
      <div class="course-details">
        <div class="course-name">
          {{ courseTemplate.courseTemplate.name }}
        </div>
        <div class="course-stats">
          <div class="course-stats-item">
            {{ "COMMON.FIELDS.TRAINING-TYPE" | translate }}: {{ trainingType }}
          </div>
          <div
            *ngIf="
              courseTemplate.courseTemplate.trainingType == TrainingType.IDD
            "
            class="course-stats-item"
          >
            {{ "COMMON.FIELDS.IDD-TIME-CREDIT" | translate }}:
            {{ durationString }} h
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <video
        *ngIf="courseTemplate.contentFile"
        #videoPlayer
        class="course-video"
        [src]="courseTemplate.contentFile.url"
        [controls]="isVideoPlaying"
      ></video>
      <div class="col-md-12 col-lg-5 training-image-column">
        <div>
          <div class="course-image-container">
            <img
              class="course-image"
              [src]="courseTemplate.courseTemplate.imageUrl"
              appTrainingImageFallback
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-7">
        <div class="course-widget">
          <div class="course-description">
            {{ courseTemplate.courseTemplate.description! }}
          </div>
          <div class="course-widget-item" *ngIf="videoDuration">
            <div class="course-widget-badge">
              <i class="dx-icon dx-svg-icon" [innerHTML]="getClockIcon()"></i>
              {{ "COMMON.FIELDS.DURATION" | translate }}:
            </div>
            <div class="course-widget-value">
              <b>{{ videoDuration }} {{ "COMMON.FIELDS.HOURS" | translate }}</b>
            </div>
          </div>
          <div class="course-widget-item" *ngIf="trainingCategories">
            <div class="course-widget-badge category-badge">
              <i
                class="dx-icon dx-svg-icon"
                [innerHTML]="getCategoryIcon()"
              ></i>
              {{ trainingCategories }}
            </div>
          </div>
          <div
            class="course-widget-item"
            *ngIf="courseTemplate.courseTemplate.link"
          >
            <div class="course-widget-badge">
              <i class="dx-icon-link dx-svg-icon-default"></i>
              {{ "MANAGE-ELEARNING.DETAIL.LINK" | translate }}:
            </div>
            <div class="course-widget-value">
              <a [href]="courseTemplate.courseTemplate.link">{{
                courseTemplate.courseTemplate.link
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="course-description-widget">
      <div class="course-widget-title">
        {{ "COMMON.FIELDS.DESCRIPTION" | translate }}
      </div>
      <dx-form
        labelLocation="top"
        [showColonAfterLabel]="false"
        [formData]="courseTemplate"
      >
        <dxi-item
          dataField="courseTemplate.descriptionExtended"
          label=""
          editorType="dxHtmlEditor"
          [editorOptions]="{
            label: '',
            height: 280,
            readOnly: true
          }"
        >
          <dxo-label text=" "></dxo-label>
        </dxi-item>
      </dx-form>
    </div>
  </app-page>
</div>

<app-course-questions-modal
  [(showModal)]="showModal"
  (submit)="navigateQuestions()"
></app-course-questions-modal>
